import React, { useState } from 'react';
import { Container, CssBaseline, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CSVForm from './components/CSVForm';
import Results from './components/Results';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  formContainer: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function App() {
  const classes = useStyles();
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const handleResults = (data) => {
    setResults(data);
    setError(null);
    setShowForm(false);
  };

  const handleError = (error) => {
    setError(error);
    setResults(null);
    setShowForm(false);
  };

  const handleBackToForm = () => {
    setResults(null);
    setError(null);
    setShowForm(true);
  };

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <header className={classes.header}>
        <Typography variant="h2" component="h1" gutterBottom>
          Trade Records Validator
        </Typography>
      </header>
      {showForm ? (
        <Box className={classes.formContainer}>
          <CSVForm onResults={handleResults} onError={handleError} />
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleBackToForm}
        >
          Back to Form
        </Button>
      )}
      {results && <Results results={results} />}
      {error && <Results error={error} />}
      <ToastContainer />
    </Container>
  );
}

export default App;
