import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import axios from 'axios';
import './CSVForm.css';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    helpText: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

function CSVForm({ onResults, onError }) {
    const classes = useStyles();
    const [csvData, setCsvData] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const apiUrl = process.env.NODE_ENV === 'production' ? '/validate' : 'http://localhost:3001/validate';

        try {
            const response = await axios.post(apiUrl, csvData, {
                headers: {
                    'Content-Type': 'text/csv',
                },
            });

            setIsLoading(false);

            if (response.status === 200) {
                onResults(response.data);
                toast.success('Validation successful!');
            } else {
                onError(response.data);
                toast.error(response.data.error || 'Validation failed!');
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                if (error.response.status === 413) {
                    onError({ error: 'Payload too large. Please reduce the size of your request and try again.' });
                    toast.error('Payload too large. Please reduce the size of your request and try again.');
                } else if (error.response.status === 429) {
                    onError({ error: 'Too many requests. Please try again later.' });
                    toast.error('Too many requests. Please try again later.');
                } else {
                    onError(error.response.data);
                    toast.error(error.response.data.error || 'Validation failed!');
                }
            } else if (error.request) {
                onError({ error: 'No response received from the server.' });
                toast.error('No response received from the server.');
            } else {
                onError({ error: 'Network error or server is down.' });
                toast.error('Network error or server is down.');
            }
        }
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
                className={classes.textField}
                label="CSV Data"
                multiline
                rows={8}
                variant="outlined"
                value={csvData}
                onChange={(e) => setCsvData(e.target.value)}
                placeholder="Paste your CSV data here..."
                required
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24} /> : 'Validate'}
            </Button>
            <Typography variant="body2" className={classes.helpText}>
                Ensure your CSV data follows the correct format as shown in the example above.
            </Typography>
        </form>
    );
}

CSVForm.propTypes = {
    onResults: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default CSVForm;
